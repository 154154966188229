<template>
  <div class="detail" v-if="viewState.isDisplayed">
    <div class="container">
      <div
        class="container-bg"
        :style="{ 'background-image': `url(${bgImgSrc})` }"
      ></div>
      <div
        class="sp-container-bg"
        :style="{ 'background-image': `url(${spBgImgSrc})` }"
      ></div>
      <div class="container-bg2">
        <icon :name="`bg2-${viewState.lectureDetail.course_id}`"></icon>
      </div>
      <div class="sp-container-bg2">
        <icon
          :name="`sp-detail-bg2-${viewState.lectureDetail.course_id}`"
        ></icon>
      </div>
      <template v-if="isLogin">
        <div
          v-if="viewState.lectureDetail.is_favorite"
          class="container-bookmark-active"
          @click="deleteFavorite()"
        >
          <icon name="bookmark-active" class="bookmark-active"></icon>
        </div>
        <div v-else class="container-bookmark" @click="addFavorite()">
          <icon name="bookmark" class="bookmark"></icon>
        </div>
      </template>
      <commonShare class="container-share" />
      <div class="content">
        <div class="content-top">
          <div class="content-title">{{ viewState.lectureDetail.name }}</div>
          <div class="content-date">
            <div class="content-date-label">公開日 :</div>
            <div class="content-date-val">
              {{ display.toDate(viewState.lectureDetail.published_at) }}
            </div>
          </div>
          <div
            v-if="viewState.lectureDetail.display_updated_at"
            class="content-update-date"
          >
            <div class="content-update-date-label">最終更新日 :</div>
            <div class="content-update-date-val">
              {{ display.toDate(viewState.lectureDetail.display_updated_at) }}
            </div>
          </div>
        </div>
        <div class="content-link">
          <div class="content-link-item" @click="overviewClick">
            <div class="content-link-item-title">Overview</div>
            <div class="content-link-item-detail">概要説明</div>
            <icon name="anchor-link" class="content-link-item-icon"></icon>
          </div>
          <div class="content-link-item" @click="chapterClick">
            <div class="content-link-item-split-line"></div>
            <div class="content-link-item-title">Chapter</div>
            <div class="content-link-item-detail">チャプター</div>
            <icon name="anchor-link" class="content-link-item-icon"></icon>
          </div>
          <div class="content-link-item" @click="teacherClick">
            <div class="content-link-item-split-line"></div>
            <div class="content-link-item-title">Teacher</div>
            <div class="content-link-item-detail">講師について</div>
            <icon name="anchor-link" class="content-link-item-icon"></icon>
          </div>
        </div>
      </div>
    </div>
    <div class="overview">
      <enterpriseOverviewHeader
        :lecture="viewState.lectureDetail"
        v-if="isEnterprise"
      ></enterpriseOverviewHeader>
      <overviewHeader
        :lecture="viewState.lectureDetail"
        :levels="viewState.levels"
        v-else
      ></overviewHeader>
      <overview :lecture="viewState.lectureDetail"></overview>
      <icon name="bg-dot-circle" class="overview-bottom-bg"></icon>
    </div>
    <div class="chapter-title-area">
      <commonTitle :title="'CHAPTER'" :subTitle="'チャプター'"></commonTitle>
    </div>
    <div class="chapter-area">
      <chapterItem
        v-for="(item, index) in viewState.chapters"
        :key="index"
        :lecture="viewState.lectureDetail"
        :chapter="item"
        :no="index + 1"
      ></chapterItem>
    </div>
    <div class="key-words-area">
      <div class="key-words-top-bg"></div>
      <icon name="bg-dot-circle" class="key-words-bottom-bg"></icon>
      <keywords :keyList="viewState.lectureDetail.keywords"></keywords>
    </div>
    <div class="detail-teacher">
      <teacher :instructor="viewState.instructor"></teacher>
    </div>
    <relatedVideos
      v-if="viewState.relatedVideos.length > 0 && !isEnterprise"
      :videos="viewState.relatedVideos"
      :levels="viewState.levels"
    ></relatedVideos>
  </div>
</template>

<script>
import util from "@/utils/util";
import http from "@/utils/http";
import display from "@/utils/display";
import overviewHeader from "@/components/overview/overviewHeader.vue";
import enterpriseOverviewHeader from "@/components/overview/enterpriseOverviewHeader.vue";
import overview from "@/components/overview/overviewBody.vue";
import commonTitle from "@/components/CommonTitle/CommonTitle.vue";
import keywords from "@/components/KeyWords/KeyWords.vue";
import relatedVideos from "@/components/CommonRelatedVideos/RelatedVideos.vue";
import chapterItem from "./components/ChapterItem.vue";
import teacher from "./components/Teacher.vue";
import commonShare from "@/components/CommonShare/CommonShare.vue";
import { ElLoading } from "element-plus";
import { defineComponent, reactive, onMounted, computed, watch } from "vue";
import { useRouter, onBeforeRouteUpdate } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  components: {
    overviewHeader,
    enterpriseOverviewHeader,
    overview,
    commonTitle,
    keywords,
    relatedVideos,
    chapterItem,
    teacher,
    commonShare,
    ElLoading,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const viewState = reactive({
      response: {
        status: 0,
        data: {},
      },
      isDisplayed: false,
      isExistRelatedVideos: false,
      lectureCode: location.pathname.split("/").slice(-1)[0],
      lectureDetail: {
        name: "",
        overview: "",
        level_id: null,
        is_like: false,
        is_favorite: false,
      },
      relatedVideos: {},
      lectureKeywords: [],
      levels: [],
      instructor: {},
      chapters: [],
    });
    const isEnterprise = computed(() => store.state.user.isEnterprise);

    window.scrollTo(0, 0);

    const bgImgSrc = computed(() =>
      require(`../../assets/img/detail-bg${
        (viewState.lectureDetail.course_id % 5) + 1
      }.png`)
    );
    const spBgImgSrc = computed(() =>
      require(`../../assets/img/sp-detail-bg${
        (viewState.lectureDetail.course_id % 5) + 1
      }.png`)
    );
    const isLogin = computed(() => store.state.user.isLogin);

    // method
    const overviewClick = () => {
      $("html, body").animate(
        {
          scrollTop: document.getElementsByClassName("overview")[0].offsetTop,
        },
        500
      );
    };
    const chapterClick = () => {
      $("html, body").animate(
        {
          scrollTop:
            document.getElementsByClassName("chapter-title-area")[0].offsetTop -
            60,
        },
        500
      );
    };
    const teacherClick = () => {
      $("html, body").animate(
        {
          scrollTop:
            document.getElementsByClassName("detail-teacher")[0].offsetTop - 60,
        },
        500
      );
    };
    const fetchLevels = () => {
      return http
        .get("/api/levels")
        .then((response) => {
          viewState.levels = util.keyBy(response.data, "id");
          return response;
        })
        .catch((error) => {
          return error.response;
        });
    };
    const fetchLecturesRelatedLectureKeywords = async (lectureId) => {
      return await http
        .get(
          `/api/lectures?lecture_id=${lectureId}&keyword_related=1&order=1&res_ptn=1&count=30`
        )
        .then((response) => {
          console.dir(response.data);
          viewState.relatedVideos = response.data;
          return response;
        })
        .catch((error) => {
          console.log("エラー:", error.response.data.errors);
          return error.response;
        });
    };
    const fetchLectureDetail = async (lectureCode) => {
      return await http
        .get(`/api/lectures/${lectureCode}?res_ptn=1&key_type=code`)
        .then((response) => {
          console.dir(response.data);
          viewState.lectureDetail = response.data;

          // keywordをキーにして重複を取り除く
          const uniqueKeywordObject = util.keyBy(
            response.data.keywords,
            "keyword"
          );
          viewState.lectureKeywords = Object.values(uniqueKeywordObject);
          return response;
        })
        .catch((error) => {
          console.log("エラー:", error.response.data.errors);
          return error.response;
        });
    };
    const fetchEnterpriseLectureDetail = async (lectureCode) => {
      return await http
        .get(`/api/enterprise-lectures/${lectureCode}?key_type=code`)
        .then((response) => {
          console.dir(response.data);
          viewState.lectureDetail = response.data;

          // keywordをキーにして重複を取り除く
          const uniqueKeywordObject = util.keyBy(
            response.data.keywords,
            "keyword"
          );
          viewState.lectureKeywords = Object.values(uniqueKeywordObject);
          return response;
        })
        .catch((error) => {
          console.log("エラー:", error.response.data.errors);
          return error.response;
        });
    };
    const fetchChapters = async (lectureId) => {
      return await http
        .get(`/api/chapters/?lecture_id=${lectureId}&res_ptn=1`)
        .then((response) => {
          console.dir(response.data);
          viewState.chapters = response.data;

          return response;
        })
        .catch((error) => {
          console.log("エラー:", error.response.data.errors);
          return error.response;
        });
    };
    const fetchInstructor = async (instructorId) => {
      return await http
        .get(`/api/instructors/?id=${instructorId}`)
        .then((response) => {
          console.dir(response.data);
          if (response.data.length > 0) {
            viewState.instructor = response.data[0];
          }

          return response;
        })
        .catch((error) => {
          console.log("エラー:", error.response.data.errors);
          return error.response;
        });
    };
    const addFavorite = () => {
      // 先にお気に入り状態にしておく
      viewState.lectureDetail.is_favorite = true;

      http
        .post(`/api/lectures/${viewState.lectureDetail.key}/favorites`)
        .then((response) => {
          viewState.lectureDetail.is_favorite = true;
          return response;
        })
        .catch((error) => {
          console.log("エラー:", error.response.data.errors);
          viewState.lectureDetail.is_favorite = false;
          return error.response;
        });
    };
    const deleteFavorite = () => {
      // 先にお気に入り解除状態にしておく
      viewState.lectureDetail.is_favorite = false;

      http
        .delete(`/api/lectures/${viewState.lectureDetail.key}/favorites`)
        .then((response) => {
          viewState.lectureDetail.is_favorite = false;
          return response;
        })
        .catch((error) => {
          console.log("エラー:", error.response.data.errors);
          viewState.lectureDetail.is_favorite = true;
          return error.response;
        });
    };

    const init = async () => {
      if (isEnterprise.value) {
        // 講座詳細取得
        viewState.response = await fetchEnterpriseLectureDetail(viewState.lectureCode);
        if (viewState.response.status == 403 || viewState.response.status == 404) {
          // 403,404はnot found画面
          loading.close();
          router.push({
            name: "notfound"
          });
          return;
        } else if (viewState.response.status >= 400) {
          // エラーの場合は一覧画面
          loading.close();
          router.push({
            name: "lecture",
          });
          return;
        }
      } else {
        // レベル一覧取得
        fetchLevels();

        // 講座詳細取得
        viewState.response = await fetchLectureDetail(viewState.lectureCode);
        if (viewState.response.status == 404) {
          // 403,404はnot found画面
          loading.close();
          router.push({
            name: "notfound"
          });
          return;
        } else 
        if (viewState.response.status >= 400) {
          // エラーの場合は一覧画面
          loading.close();
          router.push({
            name: "lecture",
          });
          return;
        }

        // 関連講座取得
        // 関連講座が取得できなかった場合は表示しない
        await fetchLecturesRelatedLectureKeywords(viewState.lectureDetail.id);
      }

      // レクチャー一覧取得
      await fetchChapters(viewState.lectureDetail.id);
      if (viewState.response.status >= 400) {
        // エラーの場合は一覧画面
        loading.close();
        router.push({
          name: "lecture",
        });
        return;
      }

      // 講師取得
      await fetchInstructor(viewState.lectureDetail.instructor_id);
      if (viewState.response.status >= 400) {
        // エラーの場合は一覧画面
        loading.close();
        router.push({
          name: "lecture",
        });
        return;
      }
      viewState.isDisplayed = true; // 表示OK
    };
    const reInit = async () => {
      console.log("re-init");
      await init();
    };

    onMounted(async () => {
      if (isLogin.value && store.state.user.servicePlanId === 0) {
        // ログイン済み、かつサーピスプラン未取得の場合、
        // 顧客情報を取得したタイミングで講座情報を取得する
        console.log("skip on-mount process");
        return;
      }

      await init();
    });
    onBeforeRouteUpdate(async (to) => {
      viewState.lectureCode  = to.params.lectureNo;
      viewState.isDisplayed = false;

      loading = ElLoading.service({
        lock: true,
        text: "Loading...",
        background: "#fff",
      });

      await init();
    })

    let loading = ElLoading.service({
      lock: true,
      text: "Loading...",
      background: "#fff",
    });
    watch(
      () => viewState.isDisplayed,
      (value) => {
        if (value) {
          loading.close();
        }
      }
    );
    watch(
      () => store.state.user.isEnterprise,
      async () => {
        await reInit();
      }
    );

    return {
      util,
      display,
      viewState,
      bgImgSrc,
      spBgImgSrc,
      isLogin,
      isEnterprise,

      // method
      overviewClick,
      chapterClick,
      teacherClick,
      addFavorite,
      deleteFavorite,
    };
  },
});
</script>

<style lang="scss" scoped>
.detail {
  display: flex;
  flex-direction: column;
  max-width: 1366px;
  margin: 0 auto;
  .container {
    position: relative;
    .container-bg {
      position: absolute;
      max-width: 1366px;
      width: 100%;
      height: 772px;
      z-index: 0;
      left: 0;
      top: 0;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .sp-container-bg {
      display: none;
    }
    .container-bg2 {
      width: 1251px;
      height: 400px;
      position: absolute;
      z-index: 1;
      right: 112px;
      top: -0.5px;
      svg {
        width: 1251px;
        height: 400px;
        z-index: 1;
      }
    }
    .sp-container-bg2 {
      display: none;
    }
    .container-bookmark {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      position: absolute;
      z-index: 100;
      right: 215px;
      top: 200px;
      backdrop-filter: blur(100px);
      .bookmark {
        width: 18px;
        height: 26px;
      }
    }
    .container-bookmark-active {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      position: absolute;
      z-index: 100;
      right: 215px;
      top: 200px;
      backdrop-filter: blur(100px);
      .bookmark-active {
        width: 19px;
        height: 27px;
        box-shadow: inset 0px 20px 12px rgba(54, 131, 245, 0.25),
          0px 20px 30px rgba(54, 131, 245, 0.25);
      }
    }
    .container-share {
      position: absolute;
      right: 115px;
      top: 440px;
    }
    .content {
      display: flex;
      flex-direction: column;
      z-index: 10;
      .content-top {
        margin-left: 115px;
        height: 299px;
        .content-title {
          margin-top: 100px;
          font-family: "Roboto Condensed", "Noto Sans JP", sans-serif;
          font-weight: 700;
          font-size: 40px;
          line-height: 70px;
          display: flex;
          align-items: center;
          letter-spacing: 0.05em;
          color: $black;
          margin-right: 30px;
          text-align: left;
        }
        .content-date {
          display: flex;
          align-items: center;
          margin-top: 22px;
          .content-date-label {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: $black;
          }
          .content-date-val {
            margin-left: 4px;
            padding-bottom: 1px;
            font-family: "Roboto Condensed", sans-serif;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.08em;
            color: rgba(0, 0, 0, 0.8);
          }
        }
        .content-update-date {
          display: flex;
          align-items: center;
          margin-top: 7px;
          .content-update-date-label {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: $black;
          }
          .content-update-date-val {
            margin-left: 4px;
            padding-bottom: 1px;
            font-family: "Roboto Condensed", sans-serif;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.08em;
            color: rgba(0, 0, 0, 0.8);
          }
        }
      }
      .content-link {
        margin-right: 315px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        :nth-child(1) {
          .content-link-item-title {
            width: 84px;
            height: 70px;
          }
          .content-link-item-detail {
            width: 80px;
            height: 70px;
          }
        }
        :nth-child(2) {
          .content-link-item-title {
            width: 81px;
            height: 70px;
          }
          .content-link-item-detail {
            width: 93px;
            height: 70px;
          }
        }
        :nth-child(3) {
          .content-link-item-title {
            width: 74px;
            height: 70px;
          }
          .content-link-item-detail {
            width: 107px;
            height: 70px;
          }
        }
        .content-link-item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 312px;
          height: 100px;
          background: rgba(255, 255, 255, 0.3);
          cursor: pointer;
          position: relative;
          .content-link-item-title {
            display: flex;
            align-items: center;
            font-family: "Roboto Condensed", sans-serif;
            font-weight: 700;
            font-size: 18px;
            line-height: 70px;
            letter-spacing: 0.08em;
            color: $black;
          }
          .content-link-item-detail {
            display: flex;
            align-items: center;
            font-size: 14px;
            letter-spacing: 0.08em;
            color: $black-50;
          }
          .content-link-item-icon {
            width: 30px;
            height: 30px;
          }
          .content-link-item-split-line {
            position: absolute;
            left: 0;
            top: 40px;
            width: 0px;
            height: 20px;
            border-right: 1px solid $black;
          }
        }
      }
    }
    @include mq(sm) {
      .container-bg {
        display: none;
      }
      .sp-container-bg {
        display: block;
        position: absolute;
        width: 100%;
        height: 891px;
        z-index: 0;
        left: 0;
        top: 0;
        background-repeat: no-repeat;
        background-size: cover;
      }
      .container-bg2 {
        display: none;
      }
      .sp-container-bg2 {
        display: block;
        width: 345.43px;
        height: 480.65px;
        position: absolute;
        z-index: 1;
        right: 30px;
        top: -1px;
        svg {
          width: 345.43px;
          height: 480.65px;
          z-index: 1;
        }
      }
      .container-bookmark {
        width: 60px;
        height: 60px;
        right: 31px;
        top: 239px;
        svg {
          width: 24px;
          height: 24px;
        }
      }
      .container-bookmark-active {
        width: 60px;
        height: 60px;
        right: 31px;
        top: 239px;
      }
      .container-share {
        top: 500px;
        right: 30px;
        width: unset;
        .container-share-text {
          font-family: "Roboto Condensed", sans-serif;
          font-weight: 700;
          font-size: 14px;
          line-height: 100%;
          display: flex;
          align-items: center;
          text-align: right;
          letter-spacing: 0.08em;
          color: $black;
        }
        .container-share-icon {
          display: none;
        }
        .sp-container-share-icon {
          display: block;
          margin-left: 5px;
          width: 18px;
          height: 18px;
        }
      }
      .content {
        margin-left: 40px;
        .content-top {
          margin-left: 0px;
          height: 299px;
          .content-title {
            margin-top: 80px;
            font-family: "Roboto Condensed", "Noto Sans JP", sans-serif;
            font-weight: 700;
            font-size: 30px;
            line-height: 50px;
            display: flex;
            align-items: center;
            letter-spacing: 0.05em;
            color: $black;
            margin-right: 50px;
          }
          .content-date {
            margin-top: 24px;
            .content-date-label {
              font-weight: 500;
              font-size: 12px;
              line-height: 100%;
              color: $black;
            }
            .content-date-val {
              margin-left: 4px;
              padding-bottom: 0px;
              font-family: "Roboto Condensed", sans-serif;
              font-weight: 500;
              font-size: 14px;
              line-height: 100%;
              letter-spacing: 0.08em;
              color: $black;
              opacity: 0.8;
            }
          }
          .content-update-date {
            margin-top: 7px;
            .content-update-date-label {
              font-weight: 500;
              font-size: 12px;
              line-height: 100%;
              color: $black;
            }
            .content-update-date-val {
              margin-left: 4px;
              padding-bottom: 0px;
              font-family: "Roboto Condensed", sans-serif;
              font-weight: 500;
              font-size: 14px;
              line-height: 100%;
              letter-spacing: 0.08em;
              color: $black;
              opacity: 0.8;
            }
          }
        }
        .content-link {
          margin-right: 90px;
          background: rgba(255, 255, 255, 0.3);
          display: block;
          .content-link-item {
            width: auto;
            height: 60px;
            position: relative;
            .content-link-item-split-line {
              position: absolute;
              top: 0px;
              left: 30px;
              right: 30px;
              width: auto;
              height: 0px;
              border-right: 0px;
              border-bottom: 1px solid $black;
            }
            .content-link-item-title {
              margin-left: 30px;
              font-family: "Roboto Condensed", sans-serif;
              font-weight: 700;
              font-size: 16px;
              line-height: 100%;
              letter-spacing: 0.08em;
              color: $black;
              margin-right: 10px;
            }
            .content-link-item-detail {
              font-size: 12px;
              line-height: 100%;
              letter-spacing: 0.08em;
              white-space: nowrap;
            }
            .content-link-item-icon {
              margin-left: auto;
              margin-right: 30px;
              min-width: 30px;
              min-height: 30px;
            }
          }
        }
      }
    }
  }
  .overview {
    max-width: 1316px;
    margin-top: 100px;
    margin-left: 50px;
    z-index: 1;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
    position: relative;
    @include mq(sm) {
      margin-top: 64px;
      margin-left: 20px;
    }
    .overviewBody {
      background: rgba(255, 255, 255, 1);
    }
    .overview-bottom-bg {
      position: absolute;
      left: -310px;
      bottom: -310px;
      width: 620px;
      height: 620px;
      z-index: -1;
      @include mq(sm) {
        left: -141px;
        bottom: -141px;
        width: 282px;
        height: 282px;
      }
    }
  }
  .chapter-title-area {
    max-width: 1316px;
    z-index: 2;
    margin-top: 68px;
    margin-left: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include mq(sm) {
      margin-left: 20px;
      margin-top: 60px;
    }
    .chapterDetail {
      min-width: 456px;
      width: 456px;
      height: 100px;
      margin-right: 115px;
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;
      display: flex;
      align-items: center;
      letter-spacing: 0.1em;
      color: $dark-grey;
      text-align: left;
      margin-left: 65px;
      @include mq(sm) {
        min-width: unset;
        margin-left: 30px;
        margin-top: 30px;
        margin-right: 50px;
        width: 295px;
        height: 150px;
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        color: $dark-grey;
      }
    }
  }
  .chapter-area {
    max-width: 1136px;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-left: 115px;
    margin-right: 115px;
    @include mq(sm) {
      margin-left: 50px;
      margin-top: 60px;
      margin-right: 50px;
    }
  }
  .key-words-area {
    margin-top: 30px;
    max-width: 1316px;
    background: $white;
    box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
    position: relative;
    @include mq(sm) {
      margin-top: 15px;
      margin-right: 20px;
    }
    .key-words-top-bg {
      position: absolute;
      width: 584px;
      height: 584px;
      top: -292px;
      right: -292px;
      background-color: $grey;
      border-radius: 50%;
      z-index: -1;
      @include mq(sm) {
        width: 255px;
        height: 255px;
        top: -128px;
        right: -147px;
      }
    }
    .key-words-bottom-bg {
      position: absolute;
      width: 620px;
      height: 620px;
      bottom: -310px;
      left: -310px;
      z-index: -1;
      @include mq(sm) {
        width: 282px;
        height: 282px;
        bottom: -141px;
        left: -141px;
      }
    }
    .key-words {
      margin-top: 62px;
      margin-left: 50px;
      @include mq(sm) {
        margin-top: 40px;
        margin-left: 0px;
        .key-words-title-area {
          margin-left: 20px;
        }
        .key-words-btns {
          margin-left: 50px;
        }
      }
    }
  }
  .detail-teacher {
    margin-left: 50px;
    margin-top: 65px;
    margin-right: 115px;
    @include mq(sm) {
      margin-left: 20px;
      margin-top: 60px;
      margin-right: 20px;
    }
  }
}
</style>
