<template>
  <div class="enterpriseOverviewHeader">
    <div class="group18">
      <div class="title1">
        <span class="ueMoji">受講区分</span>
        <span class="shitaMoji">{{ lecture.is_required ? '必修' : '任意' }}</span>
      </div>
      <div class="title2">
        <span class="ueMoji">公開日数</span>
        <span class="shitaMoji">
          <p
            v-if="lecture.remaining_days"
            :class="lecture.remaining_days !== null && lecture.remaining_days < 10 ? 'expire-soon' : ''"
          >
            残り{{ lecture.remaining_days }}日
          </p>

          <p v-else>無制限</p>
        </span>
      </div>
      <div class="title2">
        <span class="ueMoji">チャプター</span>
        <span class="shitaMoji">{{ lecture.chapters_count }}</span>
      </div>
      <div class="title2">
        <span class="ueMoji">視聴時間</span>
        <span class="shitaMoji">{{
          display.timestampToHm(lecture.total_attendance_time)
        }}</span>
      </div>
    </div>

    <div class="group1848">
      <div class="shincyoku" :style="`width:${lecture.progress_rate}%`"></div>
      <icon class="imgBook" name="overview-book-icon"></icon>
      <span class="text_shicyoku">学習進捗</span>
      <span class="mojiShincyoku">{{ lecture.progress_rate }}</span>
      <span class="percentSign">%</span>
    </div>
  </div>
</template>

<script>
import display from "@/utils/display";
import { defineComponent } from "vue";

export default defineComponent({
  name: "enterpriseOverviewHeader",
  props: ["lecture"],
  setup() {
    return {
      display,
    };
  },
});
</script>

<style lang="scss" scoped>
.enterpriseOverviewHeader {
  height: auto;
  max-width: 1201px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 0.5px solid $black-50;
  @include mq(sm) {
    height: 196px;
    flex-direction: column;
    flex-wrap: nowrap;
    border-bottom: none;
  }
  .group18 {
    min-width: 408px;
    height: 47px;
    margin-left: 0px;
    margin-top: 46px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @include mq(sm) {
      width: 100%;
      margin-top: 30px;
      height: 46px;
    }
    .title1 {
      display: flex;
      flex-direction: column;
      width: 180px;
      height: 20px;
      border-right: 0.5px solid $black-50;
      align-items: center;
      justify-content: center;
      @include mq(sm) {
        width: 65.5px;
        align-items: flex-start;
        align-items: center;
      }
      .ueMoji {
        height: 17px;
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
        letter-spacing: 0.09em;
        color: rgba(89, 88, 87, 0.8);
        @include mq(sm) {
          font-size: 10px;
          line-height: 100%;
        }
      }
      .shitaMoji {
        margin-top: 2px;
        height: 28px;
        font-weight: 700;
        font-size: 20px;
        line-height: 140%;
        letter-spacing: 0.09em;
        color: $black;
        @include mq(sm) {
          margin-top: 10px;
          font-size: 18px;
          line-height: 100%;
        }
        
        .expire-soon {
          color: $red;
        }
      }
    }
    .title2 {
      display: flex;
      flex-direction: column;
      width: 180px;
      height: 20px;
      border-right: 0.5px solid $black-50;
      align-items: center;
      justify-content: center;
      @include mq(sm) {
        width: 94px;
      }
      .ueMoji {
        height: 17px;
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
        letter-spacing: 0.09em;
        color: rgba(89, 88, 87, 0.8);
        @include mq(sm) {
          font-size: 10px;
          line-height: 100%;
        }
      }
      .shitaMoji {
        margin-top: 2px;
        height: 28px;
        font-weight: 700;
        font-size: 20px;
        line-height: 140%;
        letter-spacing: 0.09em;
        color: $black;
        @include mq(sm) {
          margin-top: 10px;
          font-size: 18px;
          line-height: 100%;
        }
      }

      &:last-child {
        @include mq(sm) {
          border-right: none;
          padding-right: 10px;
        }
      }
    }
  }
  .group1848 {
    display: flex;
    flex-direction: row;
    width: 408px;
    height: 70px;
    margin-left: 73px;
    margin-top: 33px;
    margin-bottom: 33px;
    background: rgba(89, 88, 87, 0.1);
    color: white;
    position: relative;
    @include mq(sm) {
      width: auto;
      height: 50px;
      margin: 40px 30px 30px;
      &::after {
        content: "";
        position: absolute;
        height: 0px;
        left: -30px;
        bottom: -30px;
        right: 0;
        border-bottom: 0.5px solid $black-50;
      }
    }
    .shincyoku {
      position: absolute;
      left: 0px;
      height: 70px;
      z-index: 0;
      @include mq(sm) {
        height: 50px;
      }
      background: $black;
      box-shadow: 0px 0px 30px;
    }
    .imgBook {
      width: 20px;
      height: 19px;
      margin-left: 30px;
      margin-top: 26px;
      opacity: 1;
      z-index: 0;
      @include mq(sm) {
        width: 16px;
        height: 15px;
        margin-left: 19px;
        margin-top: 18px;
      }
    }
    .text_shicyoku {
      display: block;
      min-width: 66px;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.08em;
      margin-top: 27px;
      margin-left: 30px;
      opacity: 1;
      z-index: 0;
      @include mq(sm) {
        width: 61px;
        height: 12px;
        font-weight: 700;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0.08em;
        margin-top: 19px;
        margin-left: 19px;
      }
    }
    .mojiShincyoku {
      height: 40px;
      margin-top: 13px;
      margin-left: auto;
      font-weight: 700;
      font-size: 40px;
      line-height: 40px;
      display: block;
      align-items: center;
      text-align: right;
      letter-spacing: 0.08em;
      opacity: 1;
      z-index: 0;
      @include mq(sm) {
        font-size: 30px;
        height: 30px;
        line-height: 100%;
        margin-top: 10px;
      }
    }
    .percentSign {
      width: 29px;
      height: 16px;
      margin-top: 37px;
      font-family: "Roboto Condensed", sans-serif;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      text-align: left;
      letter-spacing: 0.08em;
      z-index: 0;
      @include mq(sm) {
        font-size: 12px;
        line-height: 100%;
        width: 18px;
        height: 12px;
        margin-top: 28px;
      }
    }
  }
}
</style>
