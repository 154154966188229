<template>
  <div class="overviewBody">
    <div class="titleDIV">
      <commonTiltle
        title="OVERVIEW"
        subTitle="概要説明"
        class="title"
      ></commonTiltle>
    </div>
    <div
      class="gaiyo"
      :class="viewState.isShowAll ? 'showAll' : ''"
      v-html="sanitizeHtmlOnlyATag(display.toLink(lecture.overview))"
    >
    </div>

    <div
      v-if="!viewState.isShowAll"
      @click="viewState.isShowAll = true"
      class="readMore"
    >
      <span>read more</span><icon name="read-more"></icon>
    </div>
    <div v-else @click="viewState.isShowAll = false" class="readMore">
      <span>read less</span><icon name="read-less"></icon>
    </div>
    <!-- div class="group1866">
      <span class="fontMokuhyo">学習目標</span>
      <div
        v-for="(item, index) of viewState.meisaiList"
        :key="index"
        class="mokuhyoMeisai"
        :class="{ marginTop20: index > 0 }"
      >
        <div class="linebox">
          <div id="line" class="line"></div>
          <span class="fontNormal meisaiFont">{{ item }}</span>
        </div>
        <div class="line2" v-if="index < viewState.meisaiList.length - 1" />
      </div>
    </div -->
  </div>
</template>

<script>
import commonTiltle from "@/components/CommonTitle/CommonTitle.vue";
import { defineComponent, reactive } from "vue";
import display from "@/utils/display";
import sanitizeHtml from 'sanitize-html';

export default defineComponent({
  name: "overviewBody",
  props: ["lecture"],
  components: {
    commonTiltle,
  },
  setup() {
    const viewState = reactive({
      isShowAll: false,
    });
    const sanitizeHtmlOnlyATag = (text) => {
      return sanitizeHtml(text, {
                allowedTags: ['a'],
                disallowedTagsMode: 'escape', // 禁止されたタグをエスケープ
                allowedSchemes: ['http', 'https'],
                allowProtocolRelative: false, // 相対パス禁止
              });
    }

    return {
      viewState,
      display,
      sanitizeHtmlOnlyATag,
    };
  },
});
</script>
<style lang="scss" scoped>
.overviewBody {
  max-width: 1316px;
  height: auto;
  position: relative;
  @include mq(sm) {
    height: auto;
  }
  .br {
    max-width: 1201px;
    @include mq(sm) {
      max-width: 325px;
    }
    height: 0px;
    border-bottom: 0.5px solid $black-50;
    margin-top: 0px;
  }
  .titleDIV {
    display: flex;
    flex-direction: row;
    height: 194px;
    width: auto;
    @include mq(sm) {
      height: 138px;
    }
    .title {
      margin-top: 65px;
      @include mq(sm) {
        margin-top: 60px;
      }
    }
  }
  .gaiyo {
    margin-left: 65px;
    max-width: 1136px;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.1em;
    color: $dark-grey;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;
    padding-bottom: 65px;
    @include mq(sm) {
      margin-left: 30px;
      margin-right: 50px;
      margin-top: 30px;
      padding-bottom: 24px;
      height: 90px;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
  @include mq(sm) {
    .showAll {
      height: auto;
      overflow: auto;
      text-overflow: unset;
      display: block;
    }
  }
  .readMore {
    display: none;
    @include mq(sm) {
      display: flex;
      margin-left: 30px;
      margin-top: 10px;
      align-items: center;
      padding-bottom: 60px;
      span {
        display: flex;
        align-items: center;
        height: 18px;
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 0.1em;
        color: $secondary;
      }
      svg {
        display: flex;
        align-items: center;
        margin-left: 10px;
        width: 16px;
        height: 16px;
      }
    }
  }
  .isShow {
    display: none;
    @include mq(sm) {
      display: block;
    }
  }
  .paddingTop10 {
    padding-top: 10px;
    @include mq(sm) {
      padding-top: 0px;
    }
  }
  .group1866 {
    margin-left: 65px;
    padding-top: 30px;
    padding-bottom: 65px;
    max-width: 1136px;
    @include mq(sm) {
      margin-left: 30px;
      width: 295px;
      padding-bottom: 60px;
    }
    height: auto;
    .fontMokuhyo {
      font-family: "Roboto Condensed", sans-serif;
      font-weight: 700;
      font-size: 20px;
      display: flex;
      align-items: center;
      letter-spacing: 0.08em;
      color: $black;
      margin-top: 0px;
      margin-left: 50px;
      margin-bottom: 24px;
      @include mq(sm) {
        margin-left: 40px;
        margin-bottom: 20px;
      }
    }
    .mokuhyoMeisai {
      display: flex;
      flex-direction: column;
      .linebox {
        display: flex;
        flex-direction: row;
        .line {
          margin-top: 23px;
          margin-left: 0px;
          height: 0px;
          min-width: 20px;
          border-bottom: 0.5px solid $black-50;
          @include mq(sm) {
            margin-top: 15px;
          }
        }
      }
      .line2 {
        margin-top: 5px;
        margin-left: 0px;
        height: 0px;
        min-width: 10px;
        width: 10px;
        opacity: 0.3;
        border-bottom: 0.5px solid $black-50;
        @include mq(sm) {
          margin-top: 10px;
        }
      }
      .meisaiFont {
        margin-left: 30px;
        max-width: 1086px;
        line-height: 45px;
        @include mq(sm) {
          width: 255px;
          margin-left: 20px;
          line-height: 30px;
        }
      }
    }
    @include mq(sm) {
      .marginTop20 {
        margin-top: 20px;
      }
    }
  }
}
.fontNormal {
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  /* or 187% */
  letter-spacing: 0.1em;
  color: $dark-grey;
}
@include mq(sm) {
  .fontNormal {
    font-size: 14px;
  }
}
</style>
