<template>
  <div class="teacher">
    <div class="teacher-title-area">
      <commonTitle :title="'TEACHER'" :subTitle="'講師'"></commonTitle>
    </div>
    <div class="teacher-detail">
      <div class="teacher-img">
        <img :src="instructor.image_url" :alt="instructor.name" />
      </div>
      <div class="teacher-info">
        <div class="teacher-name">
          <div class="last-name">{{ instructor.name.slice(0, 1) }}</div>
          <div class="full-name">{{ instructor.name }}</div>
          <div class="rank">{{ instructor.position }}</div>
          <div class="company">{{ instructor.company_name }}</div>
        </div>
        <div class="teacher-text">
          <div
            class="teacher-text-content"
            :class="viewState.readMoreVisible ? 'readMoreVisible' : ''"
            v-html="sanitizeHtmlOnlyATag(display.toLink(instructor.profile))"
          >
          </div>
          <div
            class="teacher-read-more"
            @click="viewState.readMoreVisible = !viewState.readMoreVisible"
          >
            {{ viewState.readMoreVisible ? `read more` : "read less " }}&nbsp;
            <icon
              :name="`read-${viewState.readMoreVisible ? 'more' : 'less'}`"
            ></icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonTitle from "@/components/CommonTitle/CommonTitle.vue";
import { defineComponent, reactive } from "vue";
import display from "@/utils/display";
import sanitizeHtml from 'sanitize-html';

export default defineComponent({
  props: ["instructor"],
  components: {
    commonTitle,
  },
  setup() {
    const viewState = reactive({
      readMoreVisible: true,
    });
    const sanitizeHtmlOnlyATag = (text) => {
      return sanitizeHtml(text, {
                allowedTags: ['a'],
                disallowedTagsMode: 'escape', // 禁止されたタグをエスケープ
                allowedSchemes: ['http', 'https'],
                allowProtocolRelative: false, // 相対パス禁止
              });
    }

    return {
      viewState,
      display,
      sanitizeHtmlOnlyATag,
    };
  },
});
</script>

<style lang="scss" scoped>
.teacher {
  max-width: 1136px;
  .teacher-detail {
    margin-left: 65px;
    margin-top: 40px;
    display: flex;
    max-width: 1139px;
    .teacher-img {
      width: 227px;
      height: 227px;
      margin-right: 30px;
      img {
        width: 227px;
        height: 227px;
        object-fit: cover;
      }
    }
    .teacher-info {
      flex: 1;
      line-height: 1.87;
      .teacher-name {
        position: relative;
        padding-left: 96px;
        .last-name {
          position: absolute;
          top: 0;
          left: 0;
          width: 67px;
          height: 67px;
          background: $black;
          font-weight: 500;
          font-size: 40px;
          line-height: 67px;
          text-align: center;
          color: $white;
        }
        .full-name {
          font-weight: 700;
          font-size: 20px;
          letter-spacing: 0.08em;
          color: $black;
        }
        .rank {
          font-weight: 700;
          font-size: 18px;
          line-height: 30px;
          letter-spacing: 0.12em;
          color: $black;
          position: relative;
        }
        .company {
          margin-top: 20px;
          font-weight: 700;
          font-size: 18px;
          line-height: 30px;
          letter-spacing: 0.12em;
          color: $black;
        }
      }
      .teacher-text {
        margin-left: 67px;
        margin-top: 24px;
        background: $grey;
        padding: 21px 29px 50px 27px;
        position: relative;
        .teacher-text-content {
          font-size: 16px;
          line-height: 30px;
          letter-spacing: 0.1em;
          white-space: pre-wrap;
          word-wrap: break-word;
        }
        .readMoreVisible {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
        .teacher-read-more {
          cursor: pointer;
          position: absolute;
          left: 30px;
          bottom: 20px;
          font-size: 16px;
          line-height: 30px;
          display: flex;
          align-items: center;
          letter-spacing: 0.1em;
          color: $secondary;
          display: flex;
          align-items: center;
          svg {
            margin-top: 3px;
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
  @include mq(sm) {
    .teacher-detail {
      display: block;
      width: auto;
      margin: 30px 30px 0;
      position: relative;
      .teacher-img {
        position: absolute;
        width: 60px;
        height: 60px;
        img {
          width: 60px;
          height: 60px;
        }
      }
      .teacher-info {
        display: block;
        .teacher-name {
          padding-left: 85px;
          .last-name {
            display: none;
          }
          .full-name {
            font-weight: 400;
            font-size: 16px;
            letter-spacing: 0.08em;
            color: $black;
          }
          .rank {
            margin-top: 10px;
            font-weight: 700;
            font-size: 14px;
            letter-spacing: 0.12em;
            color: $black;
            position: relative;
            &::after {
              content: none;
            }
          }
          .company {
            margin-top: 20px;
            font-weight: 700;
            font-size: 14px;
            letter-spacing: 0.12em;
            color: $black;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
        .teacher-text {
          margin-left: 0;
          margin-top: 24px;
          background: $grey;
          padding: 30px 30px 60px 30px;
          position: relative;
          .teacher-text-content {
            font-size: 14px;
          }
          .readMoreVisible {
            -webkit-line-clamp: 3;
          }
          .teacher-read-more {
            bottom: 30px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
